import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import _deleteSite from "../http/deleteSite";
import deleteSiteMarketplaceMatch from "../http/deleteSiteMarketplaceMatch";
import getSite from "../http/getSite";
import getSiteEnergyFlow from "../http/getSiteEnergyFlow";
import getSiteLog from "../http/getSiteLog";
import getSiteValues from "../http/getSiteValues";
import postPreviewSite from "../http/postPreviewSite";
import postSite from "../http/postSite";
import postSiteBatteryAnalysis from "../http/postSiteBatteryAnalysis";
import postSiteMarketplaceMatch from "../http/postSiteMarketplaceMatch";
import putSite from "../http/putSite";

const useSite = ({ siteId, userOrgId, enableNonEssentialQueries }) => {
  if (enableNonEssentialQueries === undefined) {
    // This enables useSite to be mounted multiple times without
    // triggering automatic fetches of the site log and the site default values
    enableNonEssentialQueries = true;
  }
  const isAdmin = userOrgId === undefined;
  const userOrgIdArg = userOrgId;

  const queryClient = useQueryClient();
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const fetchSiteValues = useQuery(
    ["site-values"],
    async () => {
      return getSiteValues(await getToken());
    },
    {
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
      enabled: enableNonEssentialQueries && isAdmin,
    }
  );

  const LOSS_FACTORS_BY_KEY = {};
  ((fetchSiteValues.data || {})?.options?.pvLossFactorLabels || []).forEach(
    (lf, index) => {
      if (LOSS_FACTORS_BY_KEY[lf.key]) {
        throw new Error(`Duplicate loss factor key: ${lf.key}`);
      }
      LOSS_FACTORS_BY_KEY[lf.key] = { ...lf, index };
    }
  );

  const fetchSite = useQuery(
    ["site", siteId],
    async () => {
      return getSite(await getToken(), siteId, userOrgId);
    },
    {
      enabled: !!siteId,
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
    }
  );

  const fetchSiteLog = useQuery(
    ["site", siteId, "log"],
    async () => {
      return getSiteLog(await getToken(), siteId);
    },
    {
      enabled: !!siteId && isAdmin && enableNonEssentialQueries,
      placeholderData: { siteLog: [] },
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
    }
  );

  const fetchSiteEnergyFlow = useMutation(async (groupByDay) =>
    getSiteEnergyFlow(await getToken(), siteId, groupByDay)
  );

  const updateSite = useMutation(
    async (update) => putSite(await getToken(), siteId, userOrgId, update),
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries(["site", siteId]);
        if (!isAdmin) {
          queryClient.invalidateQueries(["org", userOrgIdArg]);
        }
      },
    }
  );

  const createSite = useMutation(
    async (site) => postSite(await getToken(), site, userOrgId),
    {
      onSuccess: () => {
        if (!isAdmin) {
          queryClient.invalidateQueries(["org", userOrgIdArg]);
        }
      },
    }
  );

  const createSiteBatteryAnalysis = useMutation(
    async (payload) => postSiteBatteryAnalysis(await getToken(), payload),
    {
      // Cold starts can make the first couple of runs take longer than the API gateway 30 second
      // timeout so we allow two retries
      retry: 2,
    }
  );

  const previewSite = useMutation(
    async (payload) => postPreviewSite(await getToken(), payload),
    {
      // Cold starts can make the first couple of runs take longer than the API gateway 30 second
      // timeout so we allow two retries
      retry: (failureCount, error) => {
        // Disable retry if the error status is 400
        if (error.response?.status === 400) {
          return false;
        }
        // Allow retry for other errors, up to 2 times
        return failureCount < 2;
      },
    }
  );

  const deleteSite = useMutation(
    async (siteId) => _deleteSite(await getToken(), siteId),
    {
      onSuccess: () => {
        // Only done from Admin, so no need to invalidate the Customer App only org query which returns sites
      },
    }
  );

  const siteAddMatch = useMutation(
    async (match) => {
      return await postSiteMarketplaceMatch(await getToken(), siteId, match);
    },
    {
      onSettled: (_data, _error, match) => {
        queryClient.invalidateQueries(["site", match.buyerId]);
        queryClient.invalidateQueries(["site", match.sellerId]);
        // Only done from Admin, so no need to invalidate the Customer App only org query which returns sites
      },
    }
  );

  const siteDeleteMatch = useMutation(
    async (match) => {
      return await deleteSiteMarketplaceMatch(await getToken(), siteId, match);
    },
    {
      onSettled: (_data, _error, match) => {
        queryClient.invalidateQueries(["site", match.buyerId]);
        queryClient.invalidateQueries(["site", match.sellerId]);
        // Only done from Admin, so no need to invalidate the Customer App only org query which returns sites
      },
    }
  );

  return {
    fetchSiteValues,
    LOSS_FACTORS_BY_KEY,
    fetchSite,
    fetchSiteLog,
    fetchSiteEnergyFlow,
    createSite,
    previewSite,
    updateSite,
    deleteSite,
    createSiteBatteryAnalysis,
    siteAddMatch,
    siteDeleteMatch,
  };
};

export default useSite;
