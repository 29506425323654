import {
  formatCurrency,
  formatKiloUnitsWithValue,
  formatUnitsToNearestTen,
  fractionalCurrencySymbol,
  roundCurrency,
} from "@inrange/theme-components/formatting";
import calcSiteOwnershipForOrg from "./calcSiteOwnershipForOrg";

export const formatBuyDescription = (
  organisation,
  site,
  investmentModel,
  bestBuyOffer,
  unmetDemand = false,
  procurementChartOverlay = false
) => {
  if (
    !organisation ||
    !site ||
    !bestBuyOffer ||
    // ProposalSummary has a useEffect which defaults the investmentModel
    // if this hasn't run yet, or the value passed in here is "none", then
    // we need to return and not show an SDM match
    !investmentModel ||
    investmentModel === "none"
  )
    return "";
  const ownership = calcSiteOwnershipForOrg(site, organisation);
  // Treat "none" as "license"
  const savingsWithBuy = roundCurrency(
    ownership === "ownerOccupier"
      ? bestBuyOffer.financialModels[ownership][investmentModel].savings
      : bestBuyOffer.financialModels[ownership].savings
  );
  const savingsWithoutBuy = roundCurrency(
    ownership === "ownerOccupier"
      ? site.financialModels[ownership][investmentModel].savings
      : site.financialModels[ownership].savings
  );
  const extraSavings = savingsWithBuy - savingsWithoutBuy;
  if (extraSavings <= 0) return "";

  const totalSiteDemand = bestBuyOffer.config.volume;

  if (unmetDemand) {
    return `Buy at least ${formatUnitsToNearestTen(
      totalSiteDemand,
      "kWh"
    )} of unmet demand  in the Marketplace`;
  }

  if (procurementChartOverlay) {
    return `You can save at least ${formatCurrency(
      extraSavings,
      site.currencyCode
    )} on your existing energy procurement by buying energy from the InRange network`;
  }

  return `Save at least ${formatCurrency(
    extraSavings,
    site.currencyCode
  )} by buying energy in the Marketplace`;
};

export const formatSellDescription = (bestSellOffer, currencyCode) => {
  if (!bestSellOffer) return "";
  const volumeDescription = formatKiloUnitsWithValue(
    bestSellOffer.config.volume,
    "Wh"
  );
  const tariffDescription = `${(bestSellOffer.config.tariff * 100).toFixed(2)} ${fractionalCurrencySymbol(currencyCode)}/kWh`;
  return `At least ${volumeDescription} at ${tariffDescription} sellable in the Marketplace`;
};
