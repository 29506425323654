import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../auth/UserContext";
import LandlordSiteListView from "../../views/LandlordSiteListView";
import OperationalSiteListView from "../../views/OperationalSiteListView";
import {
    generateOwnershipFilteringObject,
    generateOwnershipFilteringSlackNotification,
    getSitesByType,
} from "./dashboard-utils";

const LandlordSiteList = ({ organisation, sites, isLeaseMetrics }) => {
  const { user } = useContext(UserContext);
  const userEmail = user.email;
  let { siteType } = useParams();
  if (siteType === undefined) siteType = "landlord";
  const orgID = organisation.id;
  const [sitesByType, landlordSites] = useMemo(() => {
    const newSitesByType = getSitesByType(sites, orgID, [
      "landlord",
      "ownerOccupier",
      "operational",
    ]);
    return [
      newSitesByType,
      [...newSitesByType["landlord"], ...newSitesByType["ownerOccupier"]],
    ];
  }, [sites, orgID]);

  // Generate filtering object for tenants
  // Shape - { [orgID]: { name: string, siteIDs: string[] } }
  const tenantFilteringObject = generateOwnershipFilteringObject(
    sitesByType[siteType],
    "tenant"
  );

  if (siteType === "operational") {
    return (
      <OperationalSiteListView
        operationalSites={sitesByType["operational"]}
        organisation={organisation}
        startingSortColumn={"name"}
      />
    );
  }

  const onTenantFiltering = (filterAction, filterOrgNames) => {
    generateOwnershipFilteringSlackNotification(
      filterAction,
      organisation.id,
      filterOrgNames,
      userEmail
    );
  };

  return (
    <LandlordSiteListView
      sites={landlordSites}
      organisation={organisation}
      isLeaseMetrics={isLeaseMetrics}
      tenantFilteringObject={tenantFilteringObject}
      onTenantFiltering={onTenantFiltering}
    />
  );
};

export default LandlordSiteList;
