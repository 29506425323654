import {
  NoPaddedRow,
  Row,
  TableListSortFilters,
  TableListToggleButtons,
} from "@inrange/theme-components";
import {
  FileListHeader,
  FileListRow,
  FileListTable,
} from "@inrange/theme-components/table";
import { useEffect } from "react";
import {
  defaultSortAscendingValues,
  filterFilesByText,
  sortByColumn,
  toggleSortAscending,
} from "./FileListSortMethods";

import { useContext, useState } from "react";
import { UserContext } from "../../auth/UserContext";

const filterFilesByFileType = (files, isBilling) => {
  if (files === undefined) {
    return [];
  }

  const billingFileType = ["invoice", "statement"];
  return files.filter((file) => {
    return isBilling
      ? billingFileType.includes(file.fileType)
      : !billingFileType.includes(file.fileType);
  });
};

const FileList = ({ orgId, site, startingSortColumn }) => {
  const { user } = useContext(UserContext);
  const [textFilter, setTextFilter] = useState("");
  const [isBilling, setIsBilling] = useState(true);
  const [filteredFiles, setFilteredFiles] = useState(
    filterFilesByFileType(site.siteFiles, isBilling)
  );
  const [sortColumn, setSortColumn] = useState(startingSortColumn);
  const [sortAscending, setSortAscending] = useState(false);

  const toggleIsBilling = (newIsBilling) => {
    setSortColumn(newIsBilling ? "issueDate" : "date");
    setIsBilling(newIsBilling);
  };

  const fileTypeSelectionTabs = {
    tab1: {
      label: "Invoices & statements",
      active: isBilling,
      onClick: () => {
        toggleIsBilling(true);
      },
    },
    tab2: {
      label: null,
      active: false,
      onClick: () => {},
    },
    tab3: {
      label: "Readings & more",
      active: !isBilling,
      onClick: () => {
        toggleIsBilling(false);
      },
    },
  };

  // Sort and filter
  useEffect(() => {
    if (site.siteFiles === undefined) {
      return;
    }
    let listedSites = filterFilesByFileType(site.siteFiles, isBilling);
    if (textFilter.length > 0) {
      listedSites = filterFilesByText(listedSites, textFilter);
    }
    sortByColumn(listedSites, sortColumn, sortAscending);
    setFilteredFiles(listedSites);
  }, [textFilter, site.siteFiles, sortColumn, sortAscending, isBilling]);

  const handleColumnClick = (columnKey) => {
    if (sortColumn === columnKey) {
      toggleSortAscending(setSortAscending);
      return;
    }
    setSortColumn(columnKey);
    setSortAscending(defaultSortAscendingValues[columnKey]);
  };

  const tableHead = (
    <FileListHeader
      isBilling={isBilling}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      handleColumnClick={handleColumnClick}
    />
  );

  const tableRows = filteredFiles.length ? (
    filteredFiles.map((file) => {
      const description = file.description || file.fileName;
      const date = file.date;
      const issueDate = file.issueDate;
      const invoiceDueDate = file.invoiceDueDate;
      const invoiceStatementNumber = file.invoiceNumber || file.statementNumber;
      const invoiceStatementAmount = file.invoiceAmount || file.statementAmount;

      return (
        <FileListRow
          orgId={orgId}
          site={site}
          userEmail={user.email}
          file={file}
          key={file.fileGuid}
          isBilling={isBilling}
          description={description}
          invoiceStatementNumber={invoiceStatementNumber}
          invoiceStatementAmount={invoiceStatementAmount}
          date={date}
          issueDate={issueDate}
          invoiceDueDate={invoiceDueDate}
        />
      );
    })
  ) : (
    <FileListRow emptyRow={true} isBilling={isBilling} />
  );
  return (
    <>
      <Row $margin="8px" $padding="0">
        <TableListToggleButtons
          tabs={fileTypeSelectionTabs}
          paddingLeft={"2px"}
        />
        <TableListSortFilters
          textFilter={textFilter}
          setTextFilter={setTextFilter}
          paddingRight={"2px"}
          hideSelectFilter={true}
        />
      </Row>
      <NoPaddedRow>
        <FileListTable head={tableHead} rows={tableRows} />
      </NoPaddedRow>
    </>
  );
};

export default FileList;
