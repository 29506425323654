import { useSiteFileDownload } from "@inrange/building-manager-api-client";
import { DateTime } from "luxon";
import formatCurrency from "../../formatting/formatCurrency";
import DownloadFile from "./DownloadFile";
import TableCellData from "./TableCellData";

const formatFileDate = (date) => {
  const dateObj = DateTime.fromSeconds(date);
  return dateObj.toFormat("d LLLL yyyy");
};

const FileListRow = ({ orgId, site, userEmail, file, isBilling, emptyRow }) => {
  const { fetchSiteFileURL } = useSiteFileDownload({
    siteId: site?.id,
    file,
    isAttachment: true,
    enabled: false,
    userOrgId: orgId,
  });

  if (emptyRow) {
    const emptyDescription = isBilling
      ? "When your site has invoices and statements associated with it, you’ll be able to access them here."
      : "When your site has readings and other files associated with it, you’ll be able to access them here.";

    const EmptyCol = () => {
      return (
        <td>
          <TableCellData> </TableCellData>
        </td>
      );
    };

    return (
      <tr>
        <td colSpan={3} style={{ width: "70%" }}>
          <TableCellData>{emptyDescription}</TableCellData>
        </td>
        <EmptyCol />
        <EmptyCol />
        <EmptyCol />
        <td>
          <DownloadFile downloadDisabled={true} />
        </td>
      </tr>
    );
  }

  const description = file.description || file.fileName;
  const date = file.date;
  const issueDate = file.issueDate;
  const invoiceDueDate = file.invoiceDueDate;
  const invoiceStatementNumber = file.invoiceNumber || file.statementNumber;
  const invoiceStatementAmount = file.invoiceAmount || file.statementAmount;

  return (
    <tr key={file.fileGuid} id={file.fileGuid}>
      <td align="left">
        <TableCellData data-testid={`${file.fileName}-row-description`}>
          {description}
        </TableCellData>
      </td>
      {isBilling ? (
        <>
          <td align="left" data-testid={`${file.fileName}-row-number`}>
            <TableCellData>{invoiceStatementNumber}</TableCellData>
          </td>
          <td align="left">
            <TableCellData data-testid={`${file.fileName}-row-amount`}>
              {formatCurrency(invoiceStatementAmount, site.currencyCode, 2)}
            </TableCellData>
          </td>
          <td align="left">
            <TableCellData data-testid={`${file.fileName}-row-issue-date`}>
              {issueDate ? formatFileDate(issueDate) : "-"}
            </TableCellData>
          </td>
          <td align="left">
            <TableCellData data-testid={`${file.fileName}-row-due-date`}>
              {invoiceDueDate ? formatFileDate(invoiceDueDate) : "-"}
            </TableCellData>
          </td>
        </>
      ) : (
        <td align="left">
          <TableCellData>{date ? formatFileDate(date) : "-"}</TableCellData>
        </td>
      )}
      <td align="right">
        <DownloadFile
          downloadDisabled={fetchSiteFileURL.isLoading}
          downloadURL={fetchSiteFileURL.data?.url}
          fileName={file.fileName}
          userEmail={userEmail}
          site={site}
          refetchURL={fetchSiteFileURL.refetch}
        />
      </td>
    </tr>
  );
};

export default FileListRow;
