import { useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../auth/UserContext";
import LandlordSiteListView from "../../views/LandlordSiteListView";
import OperationalSiteListView from "../../views/OperationalSiteListView";
import TenantSiteListView from "../../views/TenantSiteListView";
import {
    generateOwnershipFilteringObject,
    generateOwnershipFilteringSlackNotification,
    getSitesByType,
} from "./dashboard-utils";

const TenantSiteList = ({ organisation, sites, isLeaseMetrics }) => {
  const { user } = useContext(UserContext);
  const userEmail = user.email;
  let { siteType } = useParams();
  const orgID = organisation.id;
  const sitesByType = getSitesByType(sites, orgID, [
    "tenant",
    "ownerOccupier",
    "operational",
  ]);

  // Generate filtering object for landlord
  // Shape - { [orgID]: { name: string, siteIDs: string[] } }
  const landlordFilteringObject = generateOwnershipFilteringObject(
    sitesByType["tenant"],
    "landlord"
  );

  if (siteType === undefined) {
    if (sitesByType["tenant"].length > 0) {
      siteType = "tenant";
    } else if (sitesByType["ownerOccupier"].length > 0) {
      siteType = "ownerOccupier";
    } else {
      siteType = "operational";
    }
  }

  if (siteType === "ownerOccupier") {
    return (
      <LandlordSiteListView
        sites={sitesByType["ownerOccupier"]}
        organisation={organisation}
        isOwnerOccupied={true}
        isLeaseMetrics={isLeaseMetrics}
      />
    );
  }

  if (siteType === "operational") {
    return (
      <OperationalSiteListView
        operationalSites={sitesByType["operational"]}
        organisation={organisation}
        startingSortColumn={"name"}
      />
    );
  }

  const onLandlordFiltering = (filterAction, filterOrgNames) => {
    generateOwnershipFilteringSlackNotification(
      filterAction,
      organisation.id,
      filterOrgNames,
      userEmail
    );
  };

  return (
    <TenantSiteListView
      sites={sitesByType["tenant"]}
      organisation={organisation}
      landlordFilteringObject={landlordFilteringObject}
      onLandlordFiltering={onLandlordFiltering}
    />
  );
};

export default TenantSiteList;
