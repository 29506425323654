import { useState } from "react";
import styled from "styled-components";
import close from "../../images/icons/close.svg";
import AddEmailUser from "../input/AddEmailUser";
import Modal from "./Modal";
import ModalView from "./ModalView";
import ShareLinkWithUserInfoBox from "./ShareLinkWithUserInfoBox";

const generateModalDescription = (modalType, shareOrgName) => {
  if (modalType === "withinOrg") {
    return (
      <p>
        Users from your organisation will have access to all sites in the
        portfolio, including sites added in the future.
      </p>
    );
  } else if (modalType === "tenant") {
    return (
      <>
        <p>
          Users from {shareOrgName ? '"' + shareOrgName + '"' : "this tenant"}{" "}
          will have access to <b>ALL of their sites</b>, including sites added
          in the future.
        </p>
        <p>
          They'll have access to tenant-specific views including data like
          generation, demand, and procurement costs, but excluding project costs
          or landlord revenue data.
        </p>
      </>
    );
  }
  return (
    <>
      <p>
        Users from {shareOrgName ? '"' + shareOrgName + '"' : "this landlord"}{" "}
        will have access to <b>ALL of their sites</b>, including sites added in
        the future.
      </p>
      <p>
        They'll have access to landlord-specific views including data like
        generation, demand, and procurement costs, as well as project costs.
      </p>
    </>
  );
};

const ShareLinkWithUser = ({
  shareAttributes = {},
  dashboardMode,
  button,
  buttonHeight,
  energyOfferOffset,
}) => {
  const {
    onShareButtonClick,
    addUser,
    userEmailsIsLoading,
    shareOrgUserEmails,
    currentOrgUserEmails,
    shareOrgName,
  } = shareAttributes;

  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [shareType, setShareType] = useState("withinOrg"); // "withinOrg", "landlord", "tenant"

  const [unknownShareOrgEmails, setUnknownShareOrgEmails] = useState([]);
  // INFO: This state is used to store emails of users that are added to an unknown org in the case
  // where the user is sharing with a tenant or landlord that is not currently associated with a site,
  // or when there are multiple tenants or landlords associated with a site.

  const onShareButtonClickHandler = (shareType) => {
    onShareButtonClick(shareType);
    setShareType(shareType);
    setShowModal(true);
    setShowDropdown(false);
  };

  const onAddUserClickHandler = (email) => {
    addUser(email, shareType);

    if (shareType !== "withinOrg") {
      if (!shareOrgName) {
        setUnknownShareOrgEmails([...unknownShareOrgEmails, email].sort());
      }
    }
  };

  const shareOrgType = dashboardMode === "Landlord" ? "tenant" : "landlord";
  let currentUserEmails = [];
  if (shareType === "withinOrg") {
    currentUserEmails = currentOrgUserEmails;
  } else {
    currentUserEmails = unknownShareOrgEmails.concat(shareOrgUserEmails);
  }

  return (
    <div style={{ position: "relative" }}>
      <ShareDropdownButton
        height={buttonHeight || "40px"}
        width="180px"
        onClick={() =>
          button
            ? onShareButtonClickHandler("withinOrg")
            : setShowDropdown(!showDropdown)
        }
        onScroll={() => setShowDropdown(false)}
        data-testid="share-user-button"
      >
        <span>{button ? "Share within my org" : "Share site(s)"}</span>
        {!button && (
          <div style={{ fontSize: "10px" }}>
            {showDropdown ? "\u25B2" : "\u25BC"}
          </div>
        )}
      </ShareDropdownButton>
      {showDropdown && (
        <ShareDropdownWrapper onClick={() => setShowDropdown(false)}>
          <ShareDropdownContainer energyOfferOffset={energyOfferOffset}>
            <ShareDropdownItem
              onClick={() => onShareButtonClickHandler(shareOrgType)}
              data-testid={`share-${shareOrgType}-option`}
            >
              With this {shareOrgType}
            </ShareDropdownItem>
            <ShareDropdownItem
              onClick={() => onShareButtonClickHandler("withinOrg")}
              data-testid="share-within-option"
            >
              Within my organisation
            </ShareDropdownItem>
          </ShareDropdownContainer>
        </ShareDropdownWrapper>
      )}
      {showModal ? (
        <Modal>
          <ModalView
            title={`Share site(s) with${
              shareType === "withinOrg" ? "in my organisation" : ` ${shareType}`
            }`}
            description={generateModalDescription(shareType, shareOrgName)}
            titlePadding="5px 0 15px 0"
          >
            <AddEmailUser
              onClick={onAddUserClickHandler}
              addButtonDisabled={userEmailsIsLoading}
              currentUserEmails={currentUserEmails}
            />
            <Label>
              These users from
              {shareType === "withinOrg"
                ? " your organisation "
                : ` this ${shareType} `}
              have access to the InRange platform:
            </Label>
            <ShareLinkWithUserInfoBoxContainer>
              {currentUserEmails.length === 0 ? (
                <NoneShared>None added yet.</NoneShared>
              ) : (
                currentUserEmails.map((email, index) => {
                  return (
                    <ShareLinkWithUserInfoBox
                      key={index}
                      email={email}
                      id={index}
                    />
                  );
                })
              )}
            </ShareLinkWithUserInfoBoxContainer>
            <CancelButton src={close} onClick={() => setShowModal(false)} />
          </ModalView>
        </Modal>
      ) : null}
    </div>
  );
};

export default ShareLinkWithUser;

const CancelButton = styled.img`
  color: #aaa;
  float: right;
  font-size: 25px;
  font-weight: normal;
  position: absolute;
  right: 9px;
  top: 16px;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
`;

const ShareLinkWithUserInfoBoxContainer = styled.div`
  overflow-y: auto;
`;

const NoneShared = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.grey300};
`;

const Label = styled.div`
  width: 506px;
  height: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.dark};
  margin: 16px 0 16px;
`;

const ShareDropdownWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 100;
`;

const ShareDropdownContainer = styled.div`
  margin-left: 16px;
  padding: 8px;
  width: 180px;
  position: absolute;
  right: ${({ energyOfferOffset }) => energyOfferOffset};
  top: 130px;
  max-height: 300px;
  overflow-y: auto;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const ShareDropdownItem = styled.li`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  list-style-type: none;
  user-select: none;
  &:hover {
    background: #efefef;
  }
`;

export const ShareDropdownButton = styled.button`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border-radius: 8px;
  border: none;
  height: ${({ height }) => height || "44px"};
  width: ${({ width }) => width || "100%"};
  padding: 16px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  span {
    width: 100%;
    text-align: center;
  }
`;
