export const currencySymbol = (currencyCode) => {
  const symbol = new Intl.NumberFormat("en", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .formatToParts(1)
    .find((part) => part.type === "currency").value;
  return symbol;
};

export const fractionalCurrencySymbol = (currencyCode) => {
  if (currencyCode === "EUR") {
    return "¢";
  }
  return "p";
};
