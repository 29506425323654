import { fractionalCurrencySymbol } from "./currency-utils";

/*
  tariffToFractionalCurrency(0.1234, "GBP") => '12.34p/kWh'
  tariffToFractionalCurrency(0.1, "GBP") => '10.00p/kWh'
*/
const tariffToFractionalCurrency = (tariff, currencyCode) =>
  (tariff * 100).toFixed(2) + fractionalCurrencySymbol(currencyCode) + "/kWh";

export default tariffToFractionalCurrency;
