import { Link } from "react-router-dom";
import styled from "styled-components";

const longestCommonPrefix = (str1, str2) => {
  let prefix = "";
  const minCommonLength = Math.min(str1.length, str2.length);

  for (let i = 0; i < minCommonLength; i++) {
    if (str1[i] === str2[i]) {
      prefix += str1[i];
    } else {
      break;
    }
  }

  return prefix;
};

const SiteNameAddressData = ({ siteName, siteAddress, siteId, orgId }) => {
  const siteUrl = `/org/${orgId}/site/${siteId}`;
  const commonAddressPrefix = longestCommonPrefix(siteName, siteAddress);
  const renderAddress =
    commonAddressPrefix.length > 0
      ? siteAddress.replace(new RegExp(`${commonAddressPrefix}(, )?`), "")
      : siteAddress;
  return (
    <TableCellSite>
      <SiteName to={siteUrl}>{siteName}</SiteName>
      <SiteAddress>{renderAddress}</SiteAddress>
    </TableCellSite>
  );
};

const TableCellSite = styled.span`
  display: flex;
  flex-direction: column;
`;

const SiteName = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #101828;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const SiteAddress = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
`;

export default SiteNameAddressData;
