import { trackUserInteraction } from "@inrange/building-manager-api-client/events";

const getSiteType = (site, orgId) => {
  const orgOwnership = site.siteOwnerships.find(
    (ownership) => ownership.orgID === orgId
  );
  return site.operationalStatus === "operational"
    ? "operational"
    : orgOwnership.ownership;
};

export const getSitesByType = (sites, orgID, allowedTypes) => {
  return sites.reduce(
    (acc, site) => {
      const siteType = getSiteType(site, orgID);
      if (acc[siteType] !== undefined) {
        acc[siteType].push(site);
      }
      return acc;
    },
    allowedTypes.reduce((acc, type) => ({ ...acc, [type]: [] }), {})
  );
};

export const generateOwnershipFilteringObject = (sites, type) => {
  return sites.reduce((acc, site) => {
    const ownerships = site.siteOwnerships;
    const ownershipsWithType = ownerships.filter(
      (ownership) => ownership.ownership === type
    );

    if (ownershipsWithType.length === 0) {
      if (!acc["undefined"]) {
        acc["undefined"] = { name: "Undefined", siteIDs: [] };
      }
      acc["undefined"].siteIDs.push(site.id);
    }

    ownershipsWithType.forEach((ownership) => {
      if (!acc[ownership.orgID]) {
        acc[ownership.orgID] = { name: ownership.name, siteIDs: [] };
      }
      acc[ownership.orgID].siteIDs.push(ownership.siteID);
    });
    return acc;
  }, {});
};

export const generateOwnershipFilteringSlackNotification = (
  filterAction,
  orgName,
  filteringOrgNames,
  userEmail
) => {
  const data = {
    filter_action: filterAction,
    org_name: orgName,
  };
  if (filteringOrgNames.length > 1) {
    data.filtered_org_names = filteringOrgNames;
  } else {
    data.filtered_org_name = filteringOrgNames[0];
  }
  trackUserInteraction(
    data,
    "BASIC_ACTION",
    "SITE_LIST_FILTER",
    userEmail,
    "customer-app"
  );
};
