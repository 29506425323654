import Select from "react-select";
import styled from "styled-components";
import magnifyingGlass from "../images/icons/magnifyingGlass.svg";
import IconInput from "./input/IconInput";

const TableListSortFilters = ({
  textFilter,
  setTextFilter,
  paddingRight,
  setFilteredSiteIDs,
  setSelectedOrgIDs,
  selectedOrgIDs,
  ownershipSelectOptions,
  onOwnershipFiltering,
  isLandlord,
  hideSelectFilter,
}) => {
  return (
    <TableListHeaderRight $paddingRight={paddingRight}>
      {!hideSelectFilter && (
        <Select
          placeholder={`Filter by ${isLandlord ? "tenant" : "landlord"}...`}
          onChange={(values, actionMeta) => {
            const filterAction = actionMeta.action;
            const filterOrgNames = [];
            if (filterAction === "clear") {
              actionMeta.removedValues.forEach((value) => {
                filterOrgNames.push(value["label"]);
              });
            } else if (filterAction === "remove-value") {
              filterOrgNames.push(actionMeta.removedValue["label"]);
            } else if (filterAction === "select-option") {
              filterOrgNames.push(actionMeta.option["label"]);
            }
            onOwnershipFiltering(filterAction, filterOrgNames);

            setFilteredSiteIDs(
              new Set(values.map((value) => value["value"]).flat())
            );
            setSelectedOrgIDs(values.map((value) => value["id"]));
          }}
          onFocus={() => {
            onOwnershipFiltering("focused", []);
          }}
          options={ownershipSelectOptions.filter(
            (option) => !selectedOrgIDs.includes(option.id)
          )}
          defaultValue={selectedOrgIDs.map((orgID) =>
            ownershipSelectOptions.find((option) => option.id === orgID)
          )}
          maxMenuHeight={320}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
              boxSizing: "border-box",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              minHeight: "44px",
              minWidth: "214px",
              maxWidth: "500px",
              fontSize: "14px",
            }),
          }}
          isMulti
          key="orgFilterSelect"
          aria-label="Filter by shared ownership"
        />
      )}
      <IconInput
        icon={magnifyingGlass}
        type="search"
        placeholder="Search"
        value={textFilter}
        onChange={(e) => setTextFilter(e.target.value)}
        width="214px"
        testId="table-list-text-filter"
      />
    </TableListHeaderRight>
  );
};

export default TableListSortFilters;

const TableListHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-grow: 1;
  padding-right: ${({ $paddingRight }) => $paddingRight || "12px"};
`;
