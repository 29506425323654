import { useOrganisation, useSite } from "@inrange/building-manager-api-client";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppDataContext } from "./AppDataContext";

function AppData({ children }) {
  const { orgId, siteId } = useParams();
  const { updateAppData } = useContext(AppDataContext);
  const { fetchOrganisation } = useOrganisation(orgId, orgId);
  const organisation = fetchOrganisation.data?.organisation;

  // Delay fetching site data until organisation is loaded
  const { fetchSite } = useSite({
    siteId: organisation ? siteId : undefined,
    userOrgId: orgId,
  });
  const site = fetchSite.data?.site;

  useEffect(() => {
    updateAppData({
      organisation: organisation,
      site: site,
    });
  }, [updateAppData, organisation, site]);

  return <div>{children}</div>;
}

export default AppData;
