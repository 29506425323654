import {
  calcBehindTheMeter,
  calcCfeScore,
  calcInRangeNetwork,
} from "@inrange/calculations/calcCfeScore";
import {
  SiteScore,
  getScoreBySiteID,
  inrangeScoreStatusColor,
  siteStatusMap,
} from "@inrange/theme-components";
import styled from "styled-components";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatMass from "../../formatting/formatMass";
import GenerationHeadroomCell from "./GenerationHeadroomCell";
import SiteLink from "./SiteLink";
import SiteNameAddressData from "./SiteNameAddressData";
import TableCellData from "./TableCellData";

const SiteRow = ({
  site,
  organisation,
  displayInRangeScoreCol,
  allSitesNotStarted,
}) => {
  const energyProcured = site.energyFlowAnnual.procurement;
  const behindTheMeter = calcBehindTheMeter(site.energyFlowAnnual) * 100;
  const inRangeNetwork = calcInRangeNetwork(site.energyFlowAnnual) * 100;
  const carbonEmissions = site.calculations.emissionsAvoided.totalAvoidance;
  const carbonFreeEnergy = calcCfeScore(site.energyFlowAnnual) * 100;
  const annualSavings = site.financialModels.tenant.savings;

  return (
    <SiteRowView
      site={site}
      organisation={organisation}
      siteId={site.id}
      siteName={site.name}
      siteAddress={site.address}
      annualSavings={annualSavings}
      energyProcured={energyProcured}
      behindTheMeter={behindTheMeter}
      inRangeNetwork={inRangeNetwork}
      carbonEmissions={carbonEmissions}
      carbonFreeEnergy={carbonFreeEnergy}
      displayInRangeScoreCol={displayInRangeScoreCol}
      allSitesNotStarted={allSitesNotStarted}
      orgID={organisation.id}
    />
  );
};

const SiteRowView = ({
  site,
  organisation,
  annualSavings,
  energyProcured,
  behindTheMeter,
  inRangeNetwork,
  carbonEmissions,
  carbonFreeEnergy,
  displayInRangeScoreCol,
  allSitesNotStarted,
  orgID,
}) => {
  const score = getScoreBySiteID(orgID, site.id);

  if (displayInRangeScoreCol) {
    return (
      <TenantSiteRowViewInrangeScoreTrue
        site={site}
        organisation={organisation}
        annualSavings={annualSavings}
        energyProcured={energyProcured}
        carbonEmissions={carbonEmissions}
        carbonFreeEnergy={carbonFreeEnergy}
        score={score}
      />
    );
  }
  return (
    <TenantSiteRowViewInrangeScoreFalse
      site={site}
      organisation={organisation}
      annualSavings={annualSavings}
      energyProcured={energyProcured}
      behindTheMeter={behindTheMeter}
      inRangeNetwork={inRangeNetwork}
      carbonEmissions={carbonEmissions}
      carbonFreeEnergy={carbonFreeEnergy}
      allSitesNotStarted={allSitesNotStarted}
    />
  );
};

export default SiteRow;

const LandlordTableCell = ({ site, organisation }) => {
  const landlords = [];
  for (const siteOwnership of site.siteOwnerships) {
    if (siteOwnership.ownership === "landlord") {
      landlords.push(<p key={siteOwnership.orgID}>{siteOwnership.name}</p>);
    }
    if (
      siteOwnership.ownership === "ownerOccupier" &&
      siteOwnership.orgID === organisation.id
    ) {
      landlords.push(
        <p key={siteOwnership.orgID}>
          {organisation.name} <br />
          <i>(Owner Occupier)</i>
        </p>
      );
    }
  }
  return (
    <td>
      {landlords.map((landlord) => (
        <div key={landlord.key}>{landlord}</div>
      ))}
    </td>
  );
};

const TenantSiteRowViewInrangeScoreTrue = ({
  site,
  organisation,
  annualSavings,
  energyProcured,
  carbonEmissions,
  carbonFreeEnergy,
  score,
}) => {
  return (
    <tr key={site.id} id={site.id}>
      <td style={{ width: "170px" }}>
        {site.operationalStatus === "notStarted" ? (
          <SiteScore
            siteScore={score}
            statusColor={inrangeScoreStatusColor[score]}
            removePointer
          />
        ) : (
          <StatusContainer>
            {siteStatusMap[site.operationalStatus].map((status, i) => {
              return <span key={i}>{status}</span>;
            })}
          </StatusContainer>
        )}
      </td>
      <td>
        <TableCellData>
          <SiteNameAddressData
            siteName={site.name}
            siteAddress={site.address}
            siteId={site.id}
            orgId={organisation.id}
          />
        </TableCellData>
      </td>
      <td>
        {formatCurrencyToNearestTen(annualSavings, site.currencyCode, 0, false)}
      </td>
      <td>{formatUnitsToNearestTen(energyProcured, "kWh")}</td>
      <LandlordTableCell site={site} organisation={organisation} />
      <td>
        <TableCellData>{formatMass(carbonEmissions)}</TableCellData>
        <TableCellData>{carbonFreeEnergy.toFixed(1)}% CFE</TableCellData>
      </td>
      <td style={{ width: "170px" }}>
        <SiteLink siteId={site.id} orgId={organisation.id} />
      </td>
    </tr>
  );
};

const TenantSiteRowViewInrangeScoreFalse = ({
  site,
  organisation,
  annualSavings,
  energyProcured,
  behindTheMeter,
  inRangeNetwork,
  carbonEmissions,
  carbonFreeEnergy,
  allSitesNotStarted,
}) => {
  return (
    <tr key={site.id} id={site.id}>
      {!allSitesNotStarted && (
        <td style={{ width: "170px" }}>
          <StatusContainer>
            {site.operationalStatus === "notStarted"
              ? "Feasibility study"
              : siteStatusMap[site.operationalStatus].map((status, i) => {
                  return <span key={i}>{status}</span>;
                })}
          </StatusContainer>
        </td>
      )}
      <td>
        <TableCellData>
          <SiteNameAddressData
            siteName={site.name}
            siteAddress={site.address}
            siteId={site.id}
            orgId={organisation.id}
          />
        </TableCellData>
      </td>
      <td>
        <GenerationHeadroomCell site={site} />
      </td>
      <td>{formatCurrencyToNearestTen(annualSavings, site.currencyCode)}</td>
      <td>{formatUnitsToNearestTen(energyProcured, "kWh")}</td>
      <td>
        <TableCellData>
          Behind the Meter: {behindTheMeter.toFixed(1)}%
        </TableCellData>
        <TableCellData>
          InRange Network: {inRangeNetwork.toFixed(1)}%
        </TableCellData>
      </td>
      <td>
        <TableCellData>{formatMass(carbonEmissions)}</TableCellData>
        <TableCellData>{carbonFreeEnergy.toFixed(1)}% CFE</TableCellData>
      </td>
      <td style={{ width: "170px" }}>
        <SiteLink siteId={site.id} orgId={organisation.id} />
      </td>
    </tr>
  );
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
