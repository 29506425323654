import styled from "styled-components";
import { currencySymbol } from "../../formatting/currency-utils";
import formatCurrency from "../../formatting/formatCurrency";
import { formatUnits } from "../../formatting/formatKiloUnits";
import {
  ChartToolTip,
  ChartToolTipBody,
  ChartToolTipHeader,
} from "../ChartTooltip";
import Tooltip from "../Tooltip";
import BarChart from "./BarChart";
import { Chart, ChartHeader, ChartLegend, ChartWrapper } from "./common";

const operationalColors = ["#00022F", "#2779A7", "#2A2C76", "#9EC4E8"];

const OperationalSiteChart = ({
  header,
  chartData,
  currency,
  currencyCode,
  exported,
  toolTipText,
  ...props
}) => {
  const keys = [
    "On-site behind the meter",
    exported ? "Exported to network" : "From InRange network",
    "Forecasted on-site",
    "Forecasted network",
  ];
  const chartLegend = [
    {
      label: keys[0],
      color: operationalColors[0],
    },
    {
      label: keys[1],
      color: operationalColors[1],
    },
    {
      label: keys[2],
      color: operationalColors[2],
    },
    {
      label: keys[3],
      color: operationalColors[3],
    },
  ];

  return (
    <ChartWrapper height={props.height} $padding={"15px 0 0 0"}>
      <OperationalChartHeader>
        <ChartHeader>{header}</ChartHeader>
        <Tooltip text={toolTipText} position={"bottomLeft"} />
      </OperationalChartHeader>
      <Chart>
        <ChartLegend chartLegend={chartLegend} />
        <BarChart
          chartData={chartData}
          tooltipFn={(e) =>
            showOperationalSiteChartTooltip(e, currency, currencyCode, exported)
          }
          indexBy="month"
          keys={keys}
          paddingProp={0.3}
          tickRotationProp={0}
          barColors={operationalColors}
          stacked={true}
          legend={currency ? currencySymbol(currencyCode) : "kWh"}
        />
      </Chart>
    </ChartWrapper>
  );
};

export default OperationalSiteChart;

// we want the dates in the tooltip to look like Jan 13 2023 - Jan 31 2023
// so we create this function
const formatTooltipDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const showOperationalSiteChartTooltip = (
  e,
  currency,
  currencyCode,
  exported
) => {
  const actualsExist = e.data["actualsMetadata"]["first_day"] !== undefined;
  const headerText = actualsExist
    ? `${formatTooltipDate(
        e.data["actualsMetadata"]["first_day"]
      )} - ${formatTooltipDate(e.data["actualsMetadata"]["last_day"])}`
    : e.data["month"];
  return (
    <ChartToolTip>
      <ChartToolTipHeader>{headerText}</ChartToolTipHeader>
      <MonthlyTooltip
        data={e.data}
        currency={currency}
        currencyCode={currencyCode}
        exported={exported}
        actualsExist={actualsExist}
      />
    </ChartToolTip>
  );
};

const MonthlyTooltip = ({
  data,
  currency,
  currencyCode,
  exported,
  actualsExist,
}) => {
  const formatValue = (key, fixedDecimalPlaces = undefined) => {
    const value = data[key] !== undefined ? data[key] : 0;
    return (
      <strong>
        {currency
          ? formatCurrency(value, currencyCode, 2)
          : formatUnits(value, "kWh", fixedDecimalPlaces)}
      </strong>
    );
  };
  const networkKey = exported ? "Exported to network" : "From InRange network";

  return actualsExist ? (
    <>
      <ChartToolTipBody>
        {"On-site behind the meter: "}
        <strong>{formatValue("On-site behind the meter", 2)}</strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        {`${networkKey}: `}
        <strong>{formatValue(networkKey, 2)}</strong>
      </ChartToolTipBody>
    </>
  ) : (
    <>
      <ChartToolTipBody>
        {"Forecasted on-site: "}
        <strong>{formatValue("Forecasted on-site")}</strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        {"Forecasted network: "}
        <strong>{formatValue("Forecasted network")}</strong>
      </ChartToolTipBody>
    </>
  );
};

const OperationalChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
`;
