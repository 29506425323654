export const getLatestActualsMonthIndex = (site, testMonthToday) => {
  // January is 0, February is 1 etc., we add 1 as the keys in our site attributes are 1-12 for each month
  const monthToday = testMonthToday || new Date().getMonth() + 1;
  let lastMonth = monthToday == 1 ? 12 : monthToday - 1;
  if (!site.energyFlowMonthlyActuals[lastMonth]["metadata"]) {
    lastMonth = lastMonth == 1 ? 12 : lastMonth - 1;
    if (!site.energyFlowMonthlyActuals[lastMonth]["metadata"]) {
      // No actuals for the previous month, or the one before
      lastMonth = undefined;
    }
  }
  return lastMonth;
};

export const getBehindMeterCost = (matchedEnergy) => {
  let behindMeterCost = 0;
  for (const matchedEnergyItem of matchedEnergy) {
    if (
      matchedEnergyItem.sinkType === "demand" &&
      matchedEnergyItem.sourceType != "gridImport"
    ) {
      behindMeterCost +=
        parseFloat(Number(matchedEnergyItem.energyFlow).toFixed(2)) *
        matchedEnergyItem.energyMatchConfig.tariff;
    }
  }
  return behindMeterCost;
};

export const loadSelectedOrgIds = (orgId, type) => {
  const item = sessionStorage.getItem(`selectedOrgIDs-${type}`);
  if (!item) {
    return [];
  }
  try {
    const value = JSON.parse(item);
    if (value && value.orgId === orgId && Array.isArray(value.selectedOrgIDs)) {
      return value.selectedOrgIDs;
    }
  } catch (e) {
    // do nothing
  }
  return [];
};

export const saveSelectedOrgIDs = (orgId, type, selectedOrgIDs) => {
  sessionStorage.setItem(
    `selectedOrgIDs-${type}`,
    JSON.stringify({ orgId, selectedOrgIDs })
  );
};

export const sortOwnershipSelectOptions = (options) => {
  return options.sort((a, b) => {
    if (a.label === "Undefined") return 1;
    if (b.label === "Undefined") return -1;
    return a.label.localeCompare(b.label);
  });
};
