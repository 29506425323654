import {
  useNetworkSiteList,
  useOrgListUsers,
  useOrganisation,
  useSite,
} from "@inrange/building-manager-api-client";
import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { usePvGeoJson } from "@inrange/inrange-data-api-client";
import {
  ErrorSidebar,
  Loaded,
  Loading,
  SidebarWithHeader,
  SimpleButton,
  SiteViewHeader,
  getScoreBySiteID,
} from "@inrange/theme-components";
import { Icons } from "@inrange/theme-components/icons";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../auth/UserContext";
import calcIsOwnerOccupied from "../utils/calcIsOwnerOccupied";
import EnergySavings from "./SiteViewTabs/EnergySavings";
import FileList from "./SiteViewTabs/FileList";
import OperationalSummary from "./SiteViewTabs/OperationalSummary";
import ProposalSummary from "./SiteViewTabs/ProposalSummary";
import PvSystemView from "./SiteViewTabs/PvSystem";
import { countSitesByOwnershipAndOperationalStatus } from "./dashboard/aggregationCalculations";
import { siteCanBuy, siteCanSell } from "./dashboard/marketplace-utils";

const SiteView = () => {
  const { logout } = useKindeAuth();
  const location = useLocation();
  const { orgId, siteId } = useParams();
  const { fetchOrganisation, useOrganisationSites } = useOrganisation(
    orgId,
    orgId
  );
  const { user } = useContext(UserContext);
  const { fetchSite } = useSite({
    siteId,
    userOrgId: orgId,
  });
  // Prefetch network sites so that when the user clicks through to the marketplace
  // the data is already loaded
  useNetworkSiteList(
    orgId,
    // Only trigger fetch once we've loaded the user's org and site data as this is more important
    fetchOrganisation.isSuccess && fetchSite.isSuccess,
    orgId
  );
  const [investmentModel, setInvestmentModel] = useState(
    fetchSite.data?.site?.activeInvestmentModel
  );

  const organisation = fetchOrganisation.data?.organisation;
  const site = fetchSite.data?.site;
  // Prefetch panel data
  usePvGeoJson(siteId, site?.pvPanel);
  // Trigger fetching of all sites for the organisation eagerly so that they are more
  // likely to be pre-loaded when the user navigates to any of them
  useOrganisationSites(
    // Only trigger fetch once we've loaded the user's org and site data as this is more important
    fetchOrganisation.isSuccess && fetchSite.isSuccess
      ? organisation?.siteOwnerships
      : []
  );
  const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
  const dashboardMode = organisation?.orgType;

  // Share with user logic
  const shareOrgType = isOwnerOccupier
    ? undefined
    : dashboardMode === "Tenant"
      ? "landlord"
      : "tenant";
  const shareOrgData = site?.siteOwnerships.filter(
    (ownershipData) => ownershipData.ownership === shareOrgType
  );
  const shareOrgIds = shareOrgData
    ? shareOrgData.map((data) => data.orgID)
    : [];
  const { orgListUsers: shareOrgUsers, addOrganisationUser: addShareOrgUser } =
    useOrgListUsers(
      // We compute shareOrgIds from the site record, so this request wont happen until
      // after the site is loaded
      shareOrgIds,
      site?.id,
      orgId
    );
  const { addOrganisationUser: addCurrentOrgUser } = useOrgListUsers(
    [orgId],
    // We don't need to pass the siteId to be allowed to get the current user's org's users
    undefined,
    orgId
  );

  useEffect(() => {
    document.body.scrollTop = 0;
    if (site && !investmentModel) {
      setInvestmentModel(site.activeInvestmentModel);
    }
  }, [site, investmentModel]);

  if (fetchOrganisation.isLoading || fetchSite.isLoading) {
    return <Loading label="Loading your data..." />;
  }

  const currentUrl = location.pathname;
  const rootPath = `/org/${orgId}/`;

  if (fetchOrganisation.isError) {
    return (
      <ErrorSidebar onClickLogout={logout}>
        <Loaded>Organisation not found</Loaded>
      </ErrorSidebar>
    );
  }

  if (fetchSite.isError) {
    return (
      <ErrorSidebar onClickLogout={logout}>
        <Loaded>
          Site not found
          <SimpleButton
            fontWeight="600"
            label="Go to dashboard"
            to={`/org/${orgId}/dashboard`}
          />
        </Loaded>
      </ErrorSidebar>
    );
  }

  const organisationSiteListSites = fetchOrganisation.data?.sites;
  const siteCounts = countSitesByOwnershipAndOperationalStatus(
    organisationSiteListSites,
    organisation.siteOwnerships
  );
  const isOperational = site.operationalStatus === "operational";

  const orgName = organisation?.name;
  const siteName = site?.name;
  document.title = orgName
    ? `InRange - ${orgName} - ${siteName}`
    : document.title;

  const irr =
    site.financialModels[isOwnerOccupier ? "ownerOccupier" : "landlord"].license
      .irr;

  const revenueModel = isOwnerOccupier
    ? site.financialModels.ownerOccupier
    : site.financialModels.landlord;

  const paybackMonths =
    site.financialModels[isOwnerOccupier ? "ownerOccupier" : "landlord"].license
      .paybackMonths;

  const ner = revenueModel.lease.revenue / site.totalBuildingArea;

  const score = getScoreBySiteID(orgId, siteId);

  const pvSystemSelected = !!site.pvDesignSystemLastUpdatedAt;
  const isGeneratingSite = site.energyFlowAnnual.generation > 0;
  const isProcuringSite =
    site.energyFlowAnnual.behindMeter + site.energyFlowAnnual.networkImport > 0;

  let energyOffer = undefined;
  if (siteCanBuy(organisation, site) || siteCanSell(organisation, site)) {
    if (
      (dashboardMode === "Tenant" && !isOwnerOccupier) ||
      (isOwnerOccupier &&
        (site.activeInvestmentModel === "lease" ||
          site.installedCapacity === 0))
    ) {
      energyOffer = {
        label: `Buy ${isProcuringSite ? "more" : ""} energy`,
        acceptOfferFn: () => {
          trackUserInteraction(
            {
              organisation_name: organisation.name,
              site_id: site.id,
              site_name: site.name,
              site_operational_status: site.operationalStatus,
              label: `Buy ${isProcuringSite ? "more" : ""} energy`,
              button_location: "top nav",
            },
            "ENERGY_OFFER",
            "ENERGY_OFFER_SITE_BUTTON_CLICK",
            user.email.toLowerCase(),
            "customer-app"
          );
        },
        acceptOfferUrl: `/org/${organisation.id}/marketplace?siteId=${site.id}&offerType=buy`,
      };
    } else if (site.installedCapacity > 0) {
      energyOffer = {
        label: "Sell more energy",
        acceptOfferFn: () => {
          trackUserInteraction(
            {
              organisation_name: organisation.name,
              site_id: site.id,
              site_name: site.name,
              site_operational_status: site.operationalStatus,
              label: "Sell more energy",
              button_location: "top nav",
            },
            "ENERGY_OFFER",
            "ENERGY_OFFER_SITE_BUTTON_CLICK",
            user.email.toLowerCase(),
            "customer-app"
          );
        },
        acceptOfferUrl: `/org/${organisation.id}/marketplace?siteId=${site.id}&offerType=sell`,
      };
    }
  }

  const shareOrgNames = shareOrgData.map((data) => data.name);
  const shareOrgName =
    shareOrgNames.length === 1 ? shareOrgNames[0] : undefined;

  const shareOrgUserEmails = [];
  shareOrgUsers.forEach((query) => {
    if (query.isSuccess) {
      shareOrgUserEmails.push(...query.data.user_emails);
    }
  });

  const currentOrgUserEmails = organisation.userEmails;

  async function handleAddUser(email, shareType) {
    if (!email) return null;

    if (shareType !== "withinOrg") {
      if (shareOrgIds.length !== 1) {
        trackUserInteraction(
          {
            invitee_organisation_email: email,
            invitee_organisation_id: undefined,
            inviter_organisation_email: user.email,
            inviter_organisation_id: organisation.id,
            type: shareType,
            multiple_orgs: shareOrgIds.length > 1,
            site_id: site.id,
            site_name: site.name,
            site_operational_status: site.operationalStatus,
          },
          "BASIC_ACTION",
          "INVITE_CREATED",
          email.toLowerCase(),
          "customer-app"
        );
        return;
      }
    }

    const addFunction =
      shareType === "withinOrg" ? addCurrentOrgUser : addShareOrgUser;
    const shareOrgId =
      shareType === "withinOrg" ? organisation.id : shareOrgIds[0];

    await addFunction.mutateAsync(
      {
        email: email,
        siteId: site.id,
        orgId: shareOrgId,
      },
      {
        onSuccess: () => {
          trackUserInteraction(
            {
              invitee_organisation_email: email,
              invitee_organisation_id: shareOrgId,
              inviter_organisation_email: user.email,
              inviter_organisation_id: organisation.id,
              type: shareType,
              site_id: site.id,
              site_name: site.name,
              site_operational_status: site.operationalStatus,
            },
            "BASIC_ACTION",
            "INVITE_CREATED",
            email.toLowerCase(),
            "customer-app"
          );
        },
      }
    );
  }

  const shareAttributes = {
    shareOrgUserEmails,
    currentOrgUserEmails,
    userEmailsIsLoading:
      addShareOrgUser.isLoading || addCurrentOrgUser.isLoading,
    addUser: handleAddUser,
    onShareButtonClick: (type) => {
      trackUserInteraction(
        {
          organisation_name: organisation.name,
          site_id: site.id,
          site_name: site.name,
          site_operational_status: site.operationalStatus,
          type: type,
        },
        "BASIC_ACTION",
        "SHARE_SITE_CLICK",
        user.email.toLowerCase(),
        "customer-app"
      );
    },
    shareOrgName,
  };

  if (isOperational) {
    return (
      <SiteViewOperational
        organisation={organisation}
        site={site}
        siteCounts={siteCounts}
        currentUrl={currentUrl}
        userEmail={user.email}
        rootPath={rootPath}
        showPvSystemTab={pvSystemSelected && isGeneratingSite}
        isOwnerOccupier={isOwnerOccupier}
        dashboardMode={dashboardMode}
        energyOffer={energyOffer}
        showEnergySavingsTab={false}
        shareAttributes={shareAttributes}
      />
    );
  }

  return (
    <SiteViewProposal
      organisation={organisation}
      dashboardMode={dashboardMode}
      site={site}
      siteCounts={siteCounts}
      currentUrl={currentUrl}
      userEmail={user.email}
      rootPath={rootPath}
      irr={irr}
      ner={ner}
      paybackMonths={paybackMonths}
      energyOffer={energyOffer}
      showPvSystemTab={pvSystemSelected && isGeneratingSite}
      isOwnerOccupier={isOwnerOccupier}
      isGeneratingSite={isGeneratingSite}
      isProcuringSite={isProcuringSite}
      showEnergySavingsTab={true}
      score={score}
      investmentModel={investmentModel}
      setInvestmentModel={setInvestmentModel}
      shareAttributes={shareAttributes}
    />
  );
};

export default SiteView;

const SiteViewProposal = ({
  organisation,
  dashboardMode,
  site,
  siteCounts,
  currentUrl,
  userEmail,
  rootPath,
  energyOffer,
  showPvSystemTab,
  isOwnerOccupier,
  isGeneratingSite,
  isProcuringSite,
  irr,
  ner,
  paybackMonths,
  showEnergySavingsTab,
  score,
  investmentModel,
  setInvestmentModel,
  shareAttributes,
}) => {
  const { logout } = useKindeAuth();
  const { currentTab } = useParams();
  const { user, onOrgSwitch } = useContext(UserContext);

  const kindeOrgCode = user.kinde_org_code;
  const kindeOrgs = user.kinde_orgs;

  let tabCrumb = "Summary";
  let tab = (
    <ProposalSummary
      site={site}
      organisation={organisation}
      irr={irr}
      ner={ner}
      paybackMonths={paybackMonths}
      investmentModel={investmentModel}
      setInvestmentModel={setInvestmentModel}
      isOwnerOccupier={isOwnerOccupier}
      isGeneratingSite={isGeneratingSite}
      isProcuringSite={isProcuringSite}
      dashboardMode={dashboardMode}
    />
  );

  if (currentTab === "pv-system") {
    tab = <PvSystemView organisation={organisation} site={site} />;
    tabCrumb = "PV System Design";
  }

  if (currentTab === "energy-savings") {
    tab = (
      <EnergySavings
        site={site}
        isOwnerOccupier={isOwnerOccupier}
        investmentModel={investmentModel}
        isGeneratingSite={isGeneratingSite}
      />
    );
    tabCrumb = "Energy Savings";
  }

  return (
    <SidebarWithHeader
      crumbs={[organisation.name, site.name, tabCrumb]}
      currentUrl={currentUrl}
      userEmail={userEmail}
      crumbIcon={Icons.building}
      rootPath={rootPath}
      dashboardMode={dashboardMode}
      siteCounts={siteCounts}
      onClickLogout={logout}
      score={score}
      onOrgSwitch={onOrgSwitch}
      userOrgs={kindeOrgs}
      currentKindeOrg={kindeOrgCode}
      siteStatus={site.operationalStatus}
      siteOperationalDateEpochSeconds={
        site.commerciallyOperationalDateEpochSeconds
      }
    >
      <SiteViewHeader
        siteId={site.id}
        currentUrl={currentUrl}
        rootPath={rootPath}
        dashboardMode={dashboardMode}
        shareAttributes={shareAttributes}
        isOwnerOccupier={isOwnerOccupier}
        isOperational={site.operationalStatus === "operational"}
        energyOffer={energyOffer}
        showPvSystemTab={showPvSystemTab}
        showEnergySavingsTab={showEnergySavingsTab}
      />
      {tab}
    </SidebarWithHeader>
  );
};

const SiteViewOperational = ({
  organisation,
  site,
  siteCounts,
  currentUrl,
  userEmail,
  rootPath,
  isOwnerOccupier,
  dashboardMode,
  energyOffer,
  showPvSystemTab,
  showEnergySavingsTab,
  shareAttributes,
}) => {
  const { logout } = useKindeAuth();
  const { user, onOrgSwitch } = useContext(UserContext);
  const { currentTab } = useParams();

  const kindeOrgCode = user.kinde_org_code;
  const kindeOrgs = user.kinde_orgs;

  let tabCrumb = "Summary";
  let tab = <OperationalSummary site={site} />;

  if (currentTab === "pv-system") {
    tab = <PvSystemView organisation={organisation} site={site} />;
    tabCrumb = "PV System Design";
  }

  if (currentTab === "billings-and-statements") {
    tab = (
      <FileList
        orgId={organisation.id}
        site={site}
        startingSortColumn="issueDate"
      />
    );
    tabCrumb = "Billings & Statements";
  }

  return (
    <SidebarWithHeader
      crumbs={[organisation.name, site.name, tabCrumb]}
      currentUrl={currentUrl}
      userEmail={userEmail}
      crumbIcon={Icons.building}
      rootPath={rootPath}
      dashboardMode={dashboardMode}
      siteCounts={siteCounts}
      onClickLogout={logout}
      onOrgSwitch={onOrgSwitch}
      userOrgs={kindeOrgs}
      currentKindeOrg={kindeOrgCode}
    >
      <SiteViewHeader
        siteId={site.id}
        currentUrl={currentUrl}
        rootPath={rootPath}
        dashboardMode={dashboardMode}
        shareAttributes={shareAttributes}
        isOwnerOccupier={isOwnerOccupier}
        isOperational={site.operationalStatus === "operational"}
        energyOffer={energyOffer}
        showPvSystemTab={showPvSystemTab}
        showEnergySavingsTab={showEnergySavingsTab}
      />
      {tab}
    </SidebarWithHeader>
  );
};
