import { useSite } from "@inrange/building-manager-api-client";
import { createContext, useEffect, useState } from "react";

const SiteCalculationsContext = createContext();

const SiteCalculationsProvider = ({
  userOrgId,
  site,
  setSaveDisabled,
  children,
}) => {
  const { previewSite } = useSite({ userOrgId });

  // >> Location linked data
  const defaultCountryCode = site.countryCode || "GBR";
  const defaultCurrencyCode = site.currencyCode || "GBP";
  const defaultDno = site.dno || {};
  const defaultSubstationData = site.substationData || {};
  // >> Buildings
  const defaultTotalBuildingArea = site.totalBuildingArea || 0;
  // >> Demand
  const defaultTenantAnnualDemand = site.tenantAnnualDemandKwh || 0;
  // >> Generation
  const defaultPVPanelCount = site.pvPanelCount || 0;
  // >> Battery
  const defaultCostInputsBattery = site.costInputsBattery || {};
  // >> Energy flow inputs
  const defaultHalfHourlyGeneration = site.halfHourlyGeneration || {};
  // >> Energy flow outputs
  const defaultEnergyFlowAnnual = site.energyFlowAnnual || { generation: 0 };
  const defaultMonthlyTenantEnergyShare =
    site.monthlyTenantEnergyShare || Array(12).fill(0);
  // >> Deal and contract attributes
  const defaultLeaseLengthMonths = site.leaseLengthMonths;
  // >> Financial modeling - outputs
  const defaultInvestmentAppraisal = site.investmentAppraisal || {};
  const defaultProjectCosts = site.projectCosts || {};
  const defaultFinancialModels = site.financialModels || {};

  const [invalidStates, setInvalidStates] = useState([]);
  const [errors, setErrors] = useState({});
  const [siteCalculations, setSiteCalculations] = useState({
    // >> Location linked data
    countryCode: defaultCountryCode,
    currencyCode: defaultCurrencyCode,
    dno: defaultDno,
    substationData: defaultSubstationData,
    // >> Buildings
    totalBuildingArea: defaultTotalBuildingArea,
    // >> Demand
    tenantAnnualDemandKwh: defaultTenantAnnualDemand,
    // >> Generation
    pvPanelCount: defaultPVPanelCount,
    // >> Battery
    costInputsBattery: defaultCostInputsBattery,
    // >> Energy flow inputs
    halfHourlyGeneration: defaultHalfHourlyGeneration,
    // >> Energy flow outputs
    energyFlowAnnual: defaultEnergyFlowAnnual,
    monthlyTenantEnergyShare: defaultMonthlyTenantEnergyShare,
    // >> Tariffs
    blendedExportTariff: site.blendedExportTariff,
    // >> Deal and contract attributes
    leaseLengthMonths: defaultLeaseLengthMonths,
    // >> Financial modeling - outputs
    investmentAppraisal: defaultInvestmentAppraisal,
    projectCosts: defaultProjectCosts,
    financialModels: defaultFinancialModels,
  });

  useEffect(() => {
    const updatedErrors = {};
    invalidStates.forEach((error) => {
      // the error response inside invalidStates can look like this:
      //   {
      //       "loc": [
      //           "costInputs",
      //           "panelRate"
      //       ],
      //       "msg": "Must be a number.",
      //       "type": "type_error.float"
      //   }
      // we can therefore join the loc array to get the path to the error
      // i.e. in this case, it would be "costInputs.panelRate"
      updatedErrors[error.loc.join(".")] = error.msg;
    });
    setErrors(updatedErrors);
  }, [invalidStates]);

  if (previewSite.isSuccess) {
    setInvalidStates([]);
    setSiteCalculations({
      ...siteCalculations,
      ...previewSite.data.site,
      // Give local code access to a millisecond accuracy time of when this calculation was completed
      updatedAt: Date.now() / 1000.0,
    });
    previewSite.reset();
  }

  if (previewSite.isError) {
    setInvalidStates(previewSite.error.response?.data?.invalid_states ?? []);
    previewSite.reset();
  }

  const updateCalculations = (site) => {
    if (setSaveDisabled) {
      setSaveDisabled(true);
    }
    previewSite.mutate(
      { site },
      {
        onSettled: () => {
          if (setSaveDisabled) {
            setSaveDisabled(false);
          }
        },
      }
    );
  };

  return (
    <SiteCalculationsContext.Provider
      value={{
        errors,
        siteCalculations,
        setSiteCalculations,
        updateCalculations,
        isCalculating: previewSite.isLoading,
      }}
    >
      {previewSite.isLoading && (
        <LoadingIndicator
          label="Calculating ..."
          testid="calculating-indicator"
        />
      )}
      {children}
    </SiteCalculationsContext.Provider>
  );
};

export { SiteCalculationsContext, SiteCalculationsProvider };

const LoadingIndicator = ({ label, testid }) => {
  return (
    <div
      className="alert alert-primary text-center"
      style={{
        position: "fixed",
        top: 10,
        right: 0,
        width: "100%",
        zIndex: 1000,
      }}
      role="alert"
      data-testid={testid}
    >
      {label}
    </div>
  );
};
